<template>
  <v-container fluid>
    <v-card flat :height="headerCardHeight">
      <v-row no-gutters class="fill-height">
        <v-col cols="3" align-self="center">
          <v-card-title class="text-h4">
            <v-icon large left color="primary lighten-3">
              mdi-google-analytics
            </v-icon>
            Kiosk Analytics

            <v-chip color="primary" class="text-h6 mt-3">
              {{ getKioskName }}
            </v-chip>
          </v-card-title>
        </v-col>
        <v-col cols="9" align-self="center">
          <v-row no-gutters>
            <v-col cols="4">
              <v-card width="85%">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total User Sessions
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalUserSessions > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalUserSessions }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card width="85%" class="">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total Questions Asked
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="count > 0" class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ totalQuestionsAsked }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="px-3 mt-3" flat>
      <v-tabs v-model="tab">
        <v-tab>
          <v-icon left>
            mdi-google-analytics
          </v-icon>
          Graphs</v-tab>
        <v-tab>
          <v-icon left>
            mdi-timer-check
          </v-icon>
          User Sessions</v-tab>
        <v-tab>
          <v-icon left>
            mdi-forum
          </v-icon>
          Q&A Analytics</v-tab>
        <v-tab>
          <v-icon left>
            mdi-video-box
          </v-icon>
          Telepresence Analytics</v-tab>
        <v-spacer></v-spacer>
        <v-card flat width="200">
          <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2" ref="durationSelector">
            <template v-slot:item="data">
              <v-list-item v-if="data.item === 'Select Date'"
                @click="$store.commit('setSingleDatePicker', true); selectedDuration = 'Select Date'">
                Select Date
              </v-list-item>
              <v-list-item v-else-if="data.item === 'Select Week'"
                @click="$store.commit('setWeekDatePicker', true); selectedDuration = 'Select Week'">
                Select Week
              </v-list-item>
              <v-list-item v-else @click="durationSetter(data.item)">{{ data.item }}</v-list-item>
            </template>
          </v-select>
        </v-card>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <reports :userSessionList="userSessionList" :selectedDuration="selectedDuration"></reports>
        </v-tab-item>
        <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item>
        <v-tab-item>
          <qnaAnalytics :analysisList="analysisList" :loading="loading">
          </qnaAnalytics>
        </v-tab-item>
        <v-tab-item>
          <TelepresenceAnalytics></TelepresenceAnalytics>
        </v-tab-item>

        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>

    </v-card>
    <v-card height="45" class="px-3 mt-3" flat>
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col cols="11" class="mt-3">
            And Unanswered questions to the following link - <a
              href="https://netorg18496.sharepoint.com/:x:/s/ARS/EXu18ZwjI_RPgfOI1aUFh9AB_PD1p1iqK5jFcegvWy4slA?e=9YRr6J"
              target="_blank">https://netorg18496.sharepoint.com/:x:/s/ARS/EXu18ZwjI_RPgfOI1aUFh9AB_PD1p1iqK5jFcegvWy4slA?e=9YRr6J</a>
          </v-col>
          <v-col cols="1" align="end" class="mt-1">
            <v-btn text class="ma-0"
              href="https://netorg18496.sharepoint.com/:x:/s/ARS/EXu18ZwjI_RPgfOI1aUFh9AB_PD1p1iqK5jFcegvWy4slA?e=9YRr6J"
              target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-col>
        </v-row>


      </v-card-text>
      <a href="https://netorg18496.sharepoint.com/:x:/s/ARS/EXu18ZwjI_RPgfOI1aUFh9AB_PD1p1iqK5jFcegvWy4slA?e=9YRr6J"
        target="_blank"></a>
    </v-card>
    <!-- <a href="https://netorg18496.sharepoint.com/:x:/s/ARS/EXu18ZwjI_RPgfOI1aUFh9AB_PD1p1iqK5jFcegvWy4slA?e=9YRr6J" target="_blank"></a> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import Reports from '../../components/dashboard/admin/kioskAnalytics/reports.vue';
import TelepresenceAnalytics from "../../components/dashboard/admin/kioskAnalytics/telepresenceAnalytics.vue";

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    Reports,
    TelepresenceAnalytics
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5.5,
      duration: ['Today', 'Yesterday', 'This Week', '2024', 'Select Date', 'Select Week', 'Fiscal Year'],
      selectedDuration: 'Today',
      count: 0,
      totalQuestionsAsked: 0
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "kioskAnalytics",
      "getWindowDimensions",
      "selectKioskAnalytics"
    ]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getKioskName() {
      let temp;
      console.log('Selected Kiosk: ', this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics))
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics)
      return temp[0].data().name
    },
    analysisList() {
      let interactions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        let qnaInteraction = event.sessionData.filter(session => session.actionType === 'QnA')
        this.totalQuestionsAsked = event.sessionData.filter(session => session.actionType === 'QnA').length
        qnaInteraction.forEach(qnaData => {
          console.log('QnA Data: ', qnaData)
          interactions.push({
            kioskId: qnaData.applicationId,
            result: qnaData.response,
            timeStamp: qnaData.timeStamp,
            transcript: qnaData.action.trim(),
            id: this.count++
          });
        })
      });

      return interactions;
    },
    telepresenceList(){
      let teleSessions = []
      return teleSessions
    },
    userSessionList() {
      console.log('this.kioskAnalytics', this.kioskAnalytics)
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        console.log('What is in the event at userSession List: ', event)
        let startTime = moment(event.startDate);
        let endTime = moment(event.endDate);
        sessions.push({
          id: event.applicationSessionId,
          applicationId: event.applicationId,
          userSessionDate: event.startDate,
          userSessionDuration: endTime.diff(startTime),
          userSessionStartTime: event.startDate,
          userSessionEndTime: event.endDate,
          userSessionLogs: event.sessionData,
        });
      });
      console.log('Session Array: ', sessions.filter(session => session.applicationId === this.selectKioskAnalytics))
      return sessions.filter(session => session.applicationId === this.selectKioskAnalytics);
      //return sessions
    },
  },
  watch: {
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5.5;
    },
    selectedDuration(newVal) {
      if (newVal === 'Today') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else if (newVal === 'Yesterday') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Yesterday',
          start: null,
          end: null
        })
      }
      else if (newVal === 'This Week') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'This Week',
          start: null,
          end: null
        })
      } else if (newVal === '2024') {
        this.$store.dispatch('getKioskAnalytics', {
          type: '2024',
          start: null,
          end: null
        })
      } else if (newVal === 'Fiscal Year') {
        this.$store.commit("setFiscalYearPicker", true)
      } else if (newVal === null) {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      }
    }
  },
  methods: {
    durationSetter(timeframe) {
      this.selectedDuration = timeframe
      this.$refs.durationSelector.isMenuActive = false;
    }
  },
  mounted() {
    if (this.allRegisteredKiosks.length === 0) {
      this.$store.dispatch("getAllRegisteredKiosks");
    }
  },
};
</script>