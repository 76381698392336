<template>
    <v-data-table fixed-header :items-per-page=-1 hide-default-footer single-select sort-by="startTime"
        :sort-desc="true" :height="kioskAnalyticsDimension.qnaAnalyticsheight" :loading="loading"
        :headers="telepresenceHeader" :items="callList" :expanded.sync="expanded" item-key="id" single-expand
        show-expand>

    </v-data-table>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex';
export default {
    name: 'telepresenceAnalytics',
    props: {
        callList: Array,
        loading: Boolean,
    },
    computed: {
        ...mapGetters(["kioskAnalyticsDimension"])
    },
    data() {
        return {
            expanded: [],
            telepresenceHeader: [
                { text: 'Session Id', value: 'sessionId' },
                { text: 'Caller', value: ''},
                { text: 'Answered By', value: ''},
                { text: 'Call Status', value: ''},
                { text: 'Date/Time', value: ''}
            ]
        }
    }
}
</script>